<template>
  <div class="container" fluid>
    <div class="row">
      <div class="offset-md-3 col-md-6">
        <div v-if="loadingbool" align="center">{{ looding_text }}</div>

        <div v-if="imgbool2" align="center">
          <img :src="`data:image/jpg;base64,${Oimg_64}`" class="fit-image150" />
          <p>&nbsp;</p>
          <p>{{ imgbool2_msg }}</p>
        </div>

        <div v-if="imgbool">
          <div align="center">
            <p>
              choose a class/label based on the revealed parts of the image.
            </p>

            <img
              :src="`data:image/jpg;base64,${Ximg_64}`"
              class="fit-image150"
            />
          </div>

          <p>&nbsp;</p>
        </div>
        <div v-if="inputbool">
          <vue3-simple-typeahead
            class="form-control"
            align="center"
            ref="inputarea"
            :items="classlist"
            :placeholder="options.placeholder"
            @selectItem="onSubmit"
            @onInput="onInput"
            @onBlur="onBlur"
            :minInputLength="options.minInputLength"
          >
          </vue3-simple-typeahead>
          <p>&nbsp;</p>
          <button
            type="button"
            class="btn btn-secondary"
            align="right"
            @click="resetInput"
          >
            clear
          </button>
        </div>
      </div>

      <div class="col-md-3">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button collapsed primary"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Possible classes (en)
              </button>
            </h2>
            <div
              id="collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
              style=""
            >
              <div class="accordion-body">
                <ul>
                  <li>airliner (airplane)</li>
                  <li>bald eagle, American eagle</li>
                  <li>cassette player</li>
                  <li>dog; border collie</li>
                  <li>dog; husky (Eskimo dog)</li>
                  <li>ferret (polecat,fitch)</li>
                  <li>great white shark</li>
                  <li>horse sorrel</li>
                  <li>missile, rocket, anti aircraft system</li>
                  <li>night snake</li>
                  <li>school bus</li>
                  <li>screen</li>
                  <li>speedboat</li>
                  <li>sports car</li>
                  <li>sunglasses</li>
                  <li>trailer truck (tractor , semi)</li>
                  <li>water jug</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Mögliche Klassen (DE)
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
              style=""
            >
              <div class="accordion-body">
                <ul class="columns">
                  <li>Adler</li>
                  <li>Bildschirm</li>
                  <li>Frettchen</li>
                  <li>Hund; border collie</li>
                  <li>Hund; husky</li>
                  <li>Kassettenspieler</li>
                  <li>LKW, sattelzug, Trakto</li>
                  <li>Motorboot</li>
                  <li>Pferd (sorrel)</li>
                  <li>Raketen</li>
                  <li>Schlange</li>
                  <li>Schulbus</li>
                  <li>Sonnenbrille</li>
                  <li>Sportwagen - Auto</li>
                  <li>Verkehrsflugzeug</li>
                  <li>Wasserkrug</li>
                  <li>Weißer Hai</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.accordion-button:focus {
  z-index: 3;
  border-color: #bce1d6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(120 194 173 / 25%);
}

.accordion-button:not(.collapsed) {
  color: #6caf9c;
  background-color: #f2f9f7;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.fit-image150 {
  height: 75%;
  width: 75%;
}
</style>

<script>
import axios from "axios";
//import nextTick from "vue";
//import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

export default {
  name: "eXperiment",
  created() {
    this.listFiltered = this.list;
  },
  data() {
    return {
      roundPlayed: 0,
      imgbool: false,
      imgbool2: false,
      inputbool: true,
      loadingbool: true,
      looding_text: "image loading. . .",
      imgbool2_msg: "",
      Ximg_64: "",
      Oimg_64: "",
      img_class: 0,
      img_class_str: "",
      x_method: 0,
      guessed_class: "",
      im_interval: null,
      tries: 0,
      x_step: 0,
      x_id: 0,
      options: {
        placeholder: "type your choice here...",
        minInputLength: 1,
      },
      classdic: {
        "airliner, airplane / Verkehrsflugzeug": [404],
        "bald eagle, American eagle / Adler": [22],
        "dog; border collie / Hund": [232],
        "cassette player / Kassettenspieler": [482],
        //"dog; english foxhound / Hund": [167],
        "ferret (polecat,fitch) / Frettchen": [358, 359],
        "great white shark / Weißer Hai": [2],
        "dog; husky (Eskimo dog) / Hund": [248, 250],
        //"dog; miniature poodle / Hund": [266],
        "missile, rocket, or anti aircraft system / Raketen": [657],
        "night snake / Schlange": [60],
        "school bus / Schulbus": [779],
        "screen / Bildschirm": [782],
        "horse sorrel / Pferd": [339],
        "speedboat / schnelles Motorboot ": [814],
        "car or sports car / Sportwagen - Auto": [817],
        "sunglasses / Sonnenbrille": [836, 837],
        "trailer truck (tractor , semi) /  LKW, sattelzug, Traktor": [867],
        "water jug / Wasserkrug": [899],
      },
      classlist: [],

      listFiltered: [],
      data: {
        input: "",
        selection: null,
      },
    };
  },
  methods: {
    show_imgs(res) {
      if (this.im_interval != null) {
        clearInterval(this.im_interval);
      }
      this.loadingbool = false;
      this.imgbool2 = false;

      this.roundPlayed = res.roundPlayed;
      //img = {  encodedImage: 'data:image/jpg;base64,/9x/4AFQSkZJRgABAXAASABIAAD...'}
      this.img_class = res.exp_class_int;
      this.Oimg_64 = res.org_img;
      let img_nr = 0;
      this.x_step = 0;
      this.x_id = res.xid;
      this.x_method = res.xidmethod;
      this.Ximg_64 = res.exp_imgs[img_nr];
      this.imgbool = true;

      //console.log(this.$refs.inputarea);
      this.$refs.inputarea.focusInput();
      img_nr++;
      this.x_step++;

      this.im_interval = setInterval(() => {
        //this.imgbool = true;
        this.Ximg_64 = res.exp_imgs[img_nr];
        img_nr++;
        this.x_step++;

        if (img_nr === 16) {
          const payload = {
            userID: this.$store.getters.StateUser,
            tries: this.tries,
            result: false,
            xstep: this.x_step,
            xid: this.x_id,
            xidmethod: this.x_method,
          };

          this.send_result(payload);
        }
      }, 4000);
    },

    async send_result(payload) {
      console.log("class sent", payload);
      const path = "/backend/ExpRes";
      axios
        .post(path, payload)
        .then((res) => {
          console.log(res.data);
          // for message alert
          //this.image = res.data["img"];
        })
        .catch((error) => {
          console.log(error);
        });

      this.nextexpdata(payload.result);
    },

    nextexpdata(earlier_res) {
      this.imgbool = false;
      this.imgbool2 = true;
      this.img_class_str = Object.keys(this.classdic).find((key) =>
        this.classdic[key].includes(this.img_class)
      );
      console.log("imb1 ", this.imgbool, " imbl2: ", this.imgbool2);

      if (earlier_res) {
        this.imgbool2_msg =
          "you are right, it's a " +
          this.img_class_str +
          ".\n you have finished " +
          this.roundPlayed +
          " rounds.";
      } else {
        this.imgbool2_msg =
          "Sorry, the correct answer was " +
          this.img_class_str +
          ".\n you have finished " +
          this.roundPlayed +
          " rounds.";
      }

      this.$forceUpdate();
      setTimeout(() => {
        console.log("sent result, and waiting for new image");
        this.getexpdata();
      }, 5000);
    },

    getexpdata() {
      this.imgbool = false;
      const payload = {
        userID: this.$store.getters.StateUser,
      };
      //  ++ttp://127.0.0.1:5000/
      const path = "/backend/Exp";
      axios
        .post(path, payload)
        .then((res) => {
          this.guessed_class = "";
          this.tries = 0;

          if (res.data == "experiment finished") {
            this.loadingbool = true;
            this.imgbool = false;
            this.imgbool2 = false;
            this.inputbool = false;
            this.looding_text = `you have finished all the experiment rounds, thank you for participating :) if you haven't already fill out the survey, please take a moment to fill out the <a href="https://questionpro.com/t/AV7TbZtubC">form</a>`;
            if (this.im_interval != null) {
              clearInterval(this.im_interval);
            }
          } else {
            //console.log(res.data);
            this.show_imgs(res.data);
          }
          //console.log(res.data);
          //this.show_imgs(res.data);
        })
        .catch((error) => {
          console.log(error);
          //this.getGames();
        });
    },

    onSubmit(item) {
      //e.preventDefault();
      console.log(item);
      this.data.selection = item;
      this.tries += 1;
      let classcode = this.classdic[item];
      if (classcode.includes(this.img_class)) {
        const payload = {
          userID: this.$store.getters.StateUser,
          tries: this.tries,
          result: true,
          xstep: this.x_step,
          xid: this.x_id,
          xidmethod: this.x_method,
        };
        this.resetInput();
        this.send_result(payload);
      } else {
        this.resetInput();
      }
    },
    onInput(event) {
      this.data.selection = null;
      this.data.input = event.input;
      this.listFiltered = event.items;
    },
    onBlur(event) {
      this.data.input = event.input;
      this.listFiltered = event.items;
    },
    resetInput() {
      this.$refs["inputarea"].input = "";
      this.data.selection = null;
      this.$refs.inputarea.focusInput();
      //this.$refs.inputarea.getInput().focus();
    },
  },
  beforeUnmount() {
    clearInterval(this.im_interval);
  },
  async mounted() {
    this.inputbool = true;
    this.$refs.inputarea.focusInput();
    //this.$refs["inputarea"].$el.focus();
    this.classlist = Object.keys(this.classdic);
    this.getexpdata();
  },
};
</script>
