<template>
  <div>
    <form @submit="onSubmit">
      <h3>register new user</h3>

      if you are new here please take a look first to the
      <router-link to="/tutorial" class="nav-link-c">tutorial page</router-link>
      , where you can find explaination on the task and the experiment in
      general.

      <div v-if="userexist" class="form-group has-danger">
        <label class="form-label mt-4" for="inputInvalid">Invalid input</label>
        <input
          type="text"
          class="form-control is-invalid"
          v-model="addUserForm.name"
        />
        <div class="invalid-feedback">
          Sorry, that username's taken. Try another?
        </div>
      </div>

      <div v-else class="form-group">
        <label class="form-label mt-4">username</label>
        <input
          class="form-control"
          type="text"
          v-model="addUserForm.name"
          required
          placeholder="create a new username, maybe something funny :)"
        />
      </div>

      <div class="form-group">
        <label>email</label>
         (optional)
        <input
          v-model="addUserForm.email"
          type="email"
          class="form-control"
          placeholder="your.email@example.com"
        />
      </div>

      <div class="form-group">
        <label>password</label>
        <input
          v-model="addUserForm.pwd"
          type="password"
          class="form-control form-control-lg"
        />
      </div>

      <button type="submit" class="btn btn-primary btn-sm">
        Sign up and start
      </button>
    </form>
  </div>
</template>

<style scoped>
.nav-link-c {
  color: #f82c35;
}
</style>

<script>
import axios from "axios";

export default {
  name: "signUp",

  data() {
    return {
      userexist: false,
      addUserForm: {
        name: "",
        email: "",
        pwd: "",
      },
      message: "",
    };
  },
  methods: {
    addUser(payload) {
      const path = "/backend/UserReg";
      axios
        .post(path, payload)
        .then((res) => {
          // console.log(res.data, res.data["userexist"]);
          // for message alert
          this.userexist = res.data["userexist"];
          if (res.data["userexist"] == false) {
            console.log("new user success");
            this.$emit("CustomEventInputChanged", [true, res.data["userID"]]);
          }
        })
        .catch((error) => {
          console.log(error);
          //this.getGames();
        });
    },
    initForm() {
      this.addUserForm.name = "";
      this.addUserForm.email = "";
      this.addUserForm.pwd = "";
    },
    onSubmit(e) {
      e.preventDefault();
      let nUser = true;
      //if (this.addGameForm.played[0]) played = true;
      const payload = {
        name: this.addUserForm.name,
        email: this.addUserForm.email,
        pwd: this.addUserForm.pwd,
        nUser,
      };
      this.addUser(payload);

      //this.initForm();
    },
    async mounted() {},
  },
};
</script>
