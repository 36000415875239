import { createStore } from "vuex";

const state = {
  user: null,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  StateUser: (state) => state.user,
};

const actions = {
  async LogIn({commit}, userID) {
    await commit("setUser", userID);
  },

  async LogOut({ commit }) {
    let user = null;
    commit("logout", user);
  },
};

const mutations = {
  setUser(state, userID) {
    state.user = userID;
  },

  logout(state, user) {
    state.user = user;
  },
};

export default createStore({
  state,
  getters,
  actions,
  mutations,
});

