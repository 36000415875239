<template>
  <div id="navb">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary" id="nav">
      <div class="container-fluid">
        <a href="#" class="navbar-brand-left"
          ><img
            src="https://www.hhi.fraunhofer.de/fileadmin/Logos/hhi_logo_de.svg"
            width="120"
            opacity=".7"
            alt="Fraunhofer-Institut für Nachrichtentechnik, Heinrich-Hertz-Institut, HHI"
        /></a>

        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarColor01"
          aria-controls="navbarColor01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarColor01">
          <ul class="navbar-nav navbar-center me-auto">
            <li v-if="!isLoggedIn" class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li v-if="!isLoggedIn" class="nav-item">
              <router-link to="/tutorial" class="nav-link"
                >Tutorial</router-link
              >
            </li>
            <li v-if="!isLoggedIn" class="nav-item">
              <router-link to="/about" class="nav-link"
                >Privacy & About</router-link
              >
            </li>
            <li v-if="!isLoggedIn" class="nav-item">
              <router-link to="/Highscore" class="nav-link"
                >High-score</router-link
              >
            </li>
          </ul>

          <button
            v-if="isLoggedIn"
            @click="logout"
            class="btn btn-outline-secondary navbar-nav navbar-center me-auto"
          >
            Log out to take a break
          </button>
        </div>

        <a href="#" class="navbar-brand-right"
          ><img
            src="@/assets/tu-berlin-logo-long-red.svg"
            width="100"
            opacity=".7"
            alt="TU Berlin"
        /></a>
      </div>
    </nav>
  </div>
</template>

<style scoped>
.navbar-nav.navbar-center {
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
}
</style>

<script>
export default {
  name: "NavBar",
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    async logout(e) {
      e.preventDefault();
      console.log("trying to logout");
      await this.$store.dispatch("LogOut");
    },
  },
};
</script>
