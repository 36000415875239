<template>
  <div>
    <form @submit="onSubmit">
      <h3>sign in old sport</h3>

      sign in with the name from last time, so you can continue from where you left off and secure your place on our
      <router-link to="/highscore" class="nav-link-c" > legends highscore table. </router-link>

      <div v-if="usernotexist" class="form-group has-danger">
        <label class="form-label mt-4" for="inputInvalid">username</label>
        <input
          type="text"
          class="form-control is-invalid"
          v-model="checkUserForm.name"
        />
        <div class="invalid-feedback">
          Sorry, that username does not exist :/
        </div>
      </div>

      <div v-else class="form-group">
        <label class="form-label mt-4">username</label>
        <input
          type="text"
          placeholder="the name used from last time :)"
          class="form-control"
          v-model="checkUserForm.name"
        />
      </div>

      <div v-if="pwdWrong" class="form-group has-danger">
        <label class="form-label mt-4" for="inputInvalid">password</label>
        <input
          type="password"
          class="form-control is-invalid"
          v-model="checkUserForm.pwd"
        />
        <div class="invalid-feedback">Sorry, password may be wrong :/</div>
      </div>

      <div v-else class="form-group">
        <label>password</label>
        <input
          type="password"
          v-model="checkUserForm.pwd"
          class="form-control form-control-lg"
        />
      </div>

      <button type="submit" class="btn btn-primary btn-sm">
        Sign in and continue
      </button>
    </form>
  </div>
</template>

<style scoped>
  .nav-link-c{
  color: #f82c35;
}

</style>


<script>
import axios from "axios";

export default {
  name: "signIn",

  data() {
    return {
      usernotexist: false,
      pwdWrong: false,
      checkUserForm: {
        name: "",
        pwd: "",
      },
      message: "",
    };
  },
  methods: {
    checkUser(payload) {
      const path = "/backend/UserReg";
      axios
        .post(path, payload)
        .then((res) => {
          // console.log(res.data);
          // for message alert "http: //127.0.0.1:5000/
          this.usernotexist = !res.data["userexist"];
          this.pwdWrong = res.data["pwdWrong"];

          if ((this.usernotexist == false) && (!this.pwdWrong)){
            console.log("user checker success")
            this.$emit("CustomEventInputChanged", [true, res.data["userID"]])
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initForm() {
      this.checkUserForm.name = "";
      this.checkUserForm.pwd = "";
    },
    onSubmit(e) {
      e.preventDefault();
      let nUser = false;
      const payload = {
        name: this.checkUserForm.name,
        pwd: this.checkUserForm.pwd,
        nUser,
      };
      this.checkUser(payload);
      //this.initForm();
    },
    async mounted() {},
  },
};
</script>
