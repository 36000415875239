<template>
  <v-container fill-height>
    <div class="jumbotron jumbotron-fluid" style="height: 100vh">
      <div class="conatiner offset-md-2 col-md-8" v-if="!isLoggedIn">
        <h2 class="text-center">
          Welcome to this experiment to acess the quality of eXplainable-AI
          methods.
          <p>&nbsp;</p>
        </h2>
        <p align="center">
          <big>
            please, take a moment to fill out the
            <a href="https://questionpro.com/t/AV7TbZtubC">survey form</a> after
            you finished a few rounds of the experiment.
          </big>
          &nbsp;
        </p>
        <p>&nbsp;</p>

        <div class="row">
          <div class="col-md-5 offset-md-1">
            <signIn @CustomEventInputChanged="Authenticat"></signIn>
          </div>
          <div class="col-md-5">
            <signUp @CustomEventInputChanged="Authenticat"></signUp>
          </div>
        </div>
      </div>
      <div v-else>
        <eXperiment></eXperiment>
      </div>
    </div>
  </v-container>
</template>

<script>
import signIn from "../components/SignIn";
import signUp from "../components/SignUp";
import eXperiment from "../components/experiment";

// @ is an alias to /src
export default {
  name: "Home",
  data() {
    return {
      Authenticated: false,
      UserID: -1,
    };
  },

  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    async logIn() {
      await this.$store.dispatch("LogIn", 1);
    },
    Authenticat(data) {
      this.Authenticated = data[0];
      this.UserID = data[1];
      this.$store.dispatch("LogIn", data[1]);
      console.log("Authenticat data");
    },
  },
  components: {
    signUp,
    signIn,
    eXperiment,
  },
};
</script>
