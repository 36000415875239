import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// to do : install bootstrap bootstrap-vue and axios with npm
import 'bootstrap/dist/css/bootstrap.css'
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';



createApp(App).use(store).use(router).directive('focus', {
    mounted(el) { // When the bound element is inserted into the DOM...
      el.focus() // Focus the element
    }
}).use(SimpleTypeahead).mount("#app");

